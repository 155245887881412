import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async currentTenantSettings() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.currentTenantSettings())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async checkApiKey(apiKey) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.checkApiKey(apiKey))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async checkTenant(tenantId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.checkTenant(tenantId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAll(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.findAll(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchByKey(key) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.findByKey(key))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async findByKeyAndCurrentTenantId(key) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.findByKeyAndCurrentTenantId(key))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async findByKeyAndReference(key, referenceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.tenant.controller.findByKeyAndReference(key, referenceId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.tenant.controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.tenant.controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async delete(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.tenant.controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async uploadLogo(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.tenant.controller.uploadLogo(), data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
