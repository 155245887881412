<template>
  <div class="row m-1">
    <div class="col-xl-6">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                FBM Charge Settings
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              These values set the FBM charge schedule for FBM shipments. The first item in an FBM shipment is charged according
              to the plan charge, and the rest of the items in a shipment are charged for the amount of <b class="text-warning">FBM Extra Item Charge</b>
              Please check <b class="text-warning">Apply Plan Charge per Individual SKU</b> if you want to apply plan charge to first item of each SKU
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12 d-flex">
                  <dx-util-check-box
                    v-model="fbmChargeIsPerSku.value"
                    class="align-self-center"
                  />
                  <span class="align-self-center ml-1">Apply Plan Charge Per SKU</span>
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.FBMCHARGEISPERSKU.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateFbmChargeIsPerSku"
                    />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12">
                  <label for="text-account-no">FBM Extra Item Charge $</label>
                  <dx-util-number-box
                    v-model="fbmExtraItemCharge.value"
                    format="$ #,##0.##"
                    styling-mode="filled"
                  />
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.FBMEXTRAITEMCHARGE.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateFbmExtraItemCharge"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'

export default {
  data() {
    return {
      managedKeys: managedKeysEnum,
      fbmChargeIsPerSku: {
        id: null,
        key: managedKeysEnum.FBMCHARGEISPERSKU.key,
        value: false,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BOOLEAN.key,
        version: null,
        referenceId: null,
      },
      fbmExtraItemCharge: {
        id: null,
        key: managedKeysEnum.FBMEXTRAITEMCHARGE.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BIGDECIMAL.key,
        version: null,
        referenceId: null,
      },
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  mounted() {
    this.getCurrentFbmChargeIsPerSku()
    this.getCurrentFbmExtraItemCharge()
  },
  methods: {
    // #region Shipping Up Charge Min Dollar
    getCurrentFbmChargeIsPerSku() {
      tenantService.fetchByKey(managedKeysEnum.FBMCHARGEISPERSKU.key).then(result => {
        const data = { ...result.data }
        data.value = data.value === 'true'
        if (data.id) {
          this.fbmChargeIsPerSku = {
            id: data.id,
            key: data.key,
            value: data.value,
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateFbmChargeIsPerSku() {
      const fbmChargeIsPerSku = { ...this.fbmChargeIsPerSku }
      if (fbmChargeIsPerSku.id) {
        tenantService
          .update(fbmChargeIsPerSku)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmChargeIsPerSku = {
              ...this.fbmChargeIsPerSku,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(fbmChargeIsPerSku)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmChargeIsPerSku = {
              ...this.fbmChargeIsPerSku,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    // #endregion
    getCurrentFbmExtraItemCharge() {
      tenantService.fetchByKey(managedKeysEnum.FBMEXTRAITEMCHARGE.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.fbmExtraItemCharge = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateFbmExtraItemCharge() {
      const fbmExtraItemCharge = { ...this.fbmExtraItemCharge }
      if (fbmExtraItemCharge.id) {
        tenantService
          .update(fbmExtraItemCharge)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmExtraItemCharge = {
              ...this.fbmExtraItemCharge,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(fbmExtraItemCharge)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.fbmExtraItemCharge = {
              ...this.fbmExtraItemCharge,
              id,
              version,
              tenantId,
            }
          })
      }
    },
  },
}
</script>

<style>

</style>
